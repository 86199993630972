import { render, staticRenderFns } from "./InterviewDetailsPage.vue?vue&type=template&id=bda83fec&scoped=true"
import script from "./InterviewDetailsPage.vue?vue&type=script&lang=js"
export * from "./InterviewDetailsPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bda83fec",
  null
  
)

export default component.exports