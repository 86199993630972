<template>
    <div class="container">
        <div class="mt-5 mb-2 fl-te-c card">
            <btn text="<-" class="" @click="$router.go(-1)" size="sm"/>
            <h4 class="text-primary mb-0 font-poppins-semibold">USER ROLE</h4>
            <div>
<!--                <btn size="sm" text="ADD" icon="fa fa-plus"-->
<!--                     @click="$refs.addUserRole.show();"></btn>-->
            </div>
        </div>
        <div class="card mt-2">
            <div class="row m-0">
                <div class="col-12">
                    <vue-table ref="table" :fields="fields" :url="listURL" :per-page="50">
                        <template slot="actions" slot-scope="props">
                            <div class="btn-group">
                                <btn text="" size="sm" icon="fa fa-pencil" color="primary"
                                     @click="editItem(props.rowData)"/>
                                <btn text="" size="sm" icon="fa fa-trash" color="danger"
                                     @click="deleteItem(props.rowData.id)"/>
                            </div>

                        </template>
                    </vue-table>

                </div>

            </div>
        </div>

        <modal ref="addUserRole" :no-close-on-backdrop="true" title="User Role" width="40r" header-color="primary" :show-additional-buttons="true">
            <template #buttons>
                <a @click="addClose"><i class="fa fa-close"></i></a>
            </template>
            <s-form @submit="addEditItem">
                <div class="row">
                    <div class="col-lg-12">
                        <validated-input label="User Role" :rules="{required: true}"
                                         class="" border-radius="1" v-model="model.name "></validated-input>
                    </div>
                     <div class="col-lg-12">
                        <validated-ajax-vue-select multiple label="Users" :rules="{required: true}" :url="userOptionsURL"
                                         class="field-required" border-radius="1" v-model="model.users "/>
                    </div>
                    <div class="col-12 text-right mt-4">
                        <btn text="Save" :loading="addLoading" loading-text="Saving..." class="px-4"></btn>
                    </div>
                </div>
            </s-form>
        </modal>
        <modal ref="editUserRole" :no-close-on-backdrop="true" title="User Role" width="40r" header-color="primary">
            <s-form @submit="addEditItem">
                <div class="row">
                    <div class="col-lg-12">
                        <validated-input label="User Role" :rules="{required: true}"
                                         class="" border-radius="1" v-model="editingItem.name" disabled></validated-input>
                    </div>
                    <div class="col-lg-12">
                        <validated-ajax-vue-select multiple class="c-input-select field-required" :rules="{required: true}"
                                               :url="userOptionsURL"
                       label="Users" v-model="editingItem.users "/>
                    </div>
                    <div class="col-12 text-right mt-4">
                        <btn text="Save" :loading="editLoading" loading-text="Saving..." class="px-4"></btn>
                    </div>
                </div>
            </s-form>
        </modal>

        <delete-modal ref="deleteModal" enter-animation="animated slideInDown" exit-animation="animated slideOutUp" :url="deleteURL" :params="{id : itemID}"
                      @response="deleteComplete">
            <p> Are you sure ?</p>
            <template #loading>
                <div class="fl-x">
                    <loading-animation/>
                    <b class="ml-3">Please Wait...</b>
                </div>
            </template>
        </delete-modal>

    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'UserRole',
    data () {
        return {
            addEditURL: urls.auth.user_role.addEdit,
            listURL: urls.auth.user_role.list,
            deleteURL: urls.auth.user_role.delete,
            userOptionsURL: urls.auth.userSelect,
            itemID: null,
            addLoading: false,
            editLoading: false,
            editStatus: false,
            editingItem: {
                id: null,
                name: null,
                users: null

            },
            originalItem: {
                id: null,
                name: null,
                users: null

            },
            model: {
                name: null,
                users: null
            },
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'User Role'
                },
                {
                    name: 'user_names',
                    title: 'Users'
                },
                {
                    name: '__slot:actions',
                    title: 'Actions'
                }
            ]
        };
    },
    methods: {
        async addEditItem () {
            try {
                if (this.editStatus) {
                    console.log('--------editingItem---------', JSON.stringify(this.editingItem.users));
                    console.log('-------originalItem----------', JSON.stringify(this.originalItem.users));
                    const originalUsers = this.originalItem.users.map(user => (typeof user === 'object' ? user.value : user).trim());
                    const editedUsers = this.editingItem.users.map(user => (typeof user === 'object' ? user.value : user).trim());
                    console.log('--------editedUsers---------', JSON.stringify(editedUsers));
                    console.log('-------originalUsers----------', JSON.stringify(originalUsers));
                    // Check if users have changed
                    const isUsersChanged = JSON.stringify(originalUsers.sort()) !== JSON.stringify(editedUsers.sort());

                    if (this.editingItem.name === this.originalItem.name && !isUsersChanged) {
                        this.$notify('No changes detected to save.', 'Warning', { type: 'warning' });
                        return;
                    }
                    this.editLoading = true;
                } else {
                    this.addLoading = true;
                }
                const response = await axios.form(this.addEditURL, this.editStatus ? this.editingItem : this.model);
                const result = response.data;
                if (result.success) {
                    if (this.editStatus) {
                        this.$notify(' User Role Edited Successfully', 'Success', { type: 'success' });
                        this.editLoading = false;
                        this.editFormSuccess();
                    } else {
                        this.$notify('User Role Added Successfully', 'Success', { type: 'success' });
                        this.addLoading = false;
                        this.addFormSuccess();
                    }
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.editLoading = false;
                    this.addLoading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.editLoading = false;
                this.addLoading = false;
            }
        },
        addFormSuccess () {
            this.$refs.addUserRole.close();
            this.model.name = null;
            this.model.users = null;
            this.$refs.table.refreshTable();
        },
        editFormSuccess () {
            this.editStatus = false;
            this.$refs.editUserRole.close();
            this.$refs.table.refreshTable();
        },
        editItem (item) {
            console.log('edit-item:>>>:', item);
            this.editStatus = true;
            this.originalItem = JSON.parse(JSON.stringify(item));
            this.editingItem.id = item.id;
            this.editingItem.name = item.name;

            // Ensure users are mapped to value-label format
            this.editingItem.users = item.users.map(user => ({
                value: user.trim(),
                label: user.trim()
            }));

            this.$refs.editUserRole.show();
        },
        deleteItem (item) {
            this.itemID = item;
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            this.$refs.table.refreshTable();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.deleteModal.close();
        },
        addClose () {
            this.model.name = '';
            this.$refs.addUserRole.close();
        }
    }
};
</script>

<style scoped>

</style>
