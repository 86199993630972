<template>
    <div class="container">
        <div class="fl-te-c">
            <h4 class="p-0 text-primary">Interview Templates</h4>
            <btn icon="fa fa-plus" icon-alignment="right" @click="showAddModal">Add</btn>
        </div>

        <!-- Loading state -->
        <div class="m-5 mt-7 text-center" v-if="dataLoading">
            <loading-animation></loading-animation>
            <br/>
            <div>Please wait while templates are being loaded...</div>
        </div>

        <!-- Details Cards for each template -->
        <div class="row" v-else-if="!dataLoading && data.length !== 0">
            <div v-for="(template, index) in data" :key="template.id" class="col-12 col-md-6 col-lg-3 mt-4">
                <details-card :data="template" :title="template.name" :index="index"
                              @showDescription="showDescription">
                    <template #body>
                        <div class="bold">Topic</div>
                        <div class="p-1 text-muted">{{ template.topic }}</div>
                        <div class="bold">No. of Questions :
                            <span class="text-muted">{{ template.number_of_questions }}</span>
                        </div>
                    </template>
                    <template #buttons>
                        <div class="btn-group">
                            <edit-btn text="" @click="setEdit(template)"/>
                            <delete-btn text="" @click="setDelete(template)"/>
                            <edit-btn icon="fa fa-eye" text="" @click="showDescription(template)"/>
                        </div>
                    </template>
                </details-card>
            </div>
        </div>

        <!-- No template Available -->
        <div v-else-if="!dataLoading && data.length === 0" class="text-center align-vertically-center">
            Templates are unavailable at the moment.
        </div>

        <!-- Modal for Add Template -->
        <modal title="Add Template" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
            <b-form :save-url="addEditUrl" @success="formSuccess" @failure="formFailed" v-slot="{ model, loading }">
                <validated-input label="Name" name="name" v-model="model.name" :rules="{ required: true }"
                                 :disabled="loading"/>
                <validated-input label="Topic" v-model="model.topic" name="topic" :rules="{ required: true }"
                                 :disabled="loading"/>
                <validated-text-area label="Description" name="description" v-model="model.description"
                                     :rules="{ required: true }" :disabled="loading"/>
                <validated-input type="number" label="Number of Questions" name="number_of_questions"
                                 v-model="model.number_of_questions" :rules="{ required: true }" :disabled="loading"/>
                <validated-input type="number" label="Mark needed to win" name="mark_needed_to_win"
                                 v-model="model.mark_needed_to_win" :rules="{ required: true }" :disabled="loading"/>
                <btn text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </b-form>
        </modal>

        <!-- Modal for Edit Template -->
        <modal title="Edit Template" ref="editModal" width="30r" header-color="primary" no-close-on-backdrop>
            <b-form :save-url="addEditUrl" @success="formSuccess" @failure="formFailed" v-slot="{ model, loading }"
                    :initial-data="editingItem">
                <validated-input label="Name" name="name" v-model="model.name" :rules="{ required: true }"
                                 :disabled="loading"/>
                <validated-input label="Topic" v-model="model.topic" name="topic" :rules="{ required: true }"
                                 :disabled="loading"/>
                <validated-text-area label="Description" name="description" v-model="model.description"
                                     :rules="{ required: true }" :disabled="loading"/>
                <validated-input type="number" label="Number of Questions" name="number_of_questions"
                                 v-model="model.number_of_questions" :rules="{ required: true }" :disabled="loading"/>
                <validated-input type="number" label="Mark needed to win" name="mark_needed_to_win"
                                 v-model="model.mark_needed_to_win" :rules="{ required: true }" :disabled="loading"/>
                <btn text="Update" loading-text="Updating..." size="sm" :disabled="loading" :loading="loading"/>
            </b-form>
        </modal>

        <!-- Delete Modal -->
        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the user <b v-html="deletingItem && deletingItem.name"></b>. Are you sure?</p>
            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>

        <!-- Modal for Template Details -->
        <modal :title="details.name" ref="detailsModal" width="35r" header-color="primary" no-close-on-backdrop>
            <div class="container">
                <div class="">
                    <span>Topic : </span> <span class="text-muted">{{ details.topic }}</span>
                </div>
                <div class=" mt-1">
                    <span>Description : </span> <span class="text-muted">{{ details.description }}</span>
                </div>
                <div class="mt-1">
                    <span>Number of Questions : </span> <span class="text-muted">{{ details.number_of_questions }}</span>
                </div>
                <div class="mt-1">
                    <span>Mark Needed to Win : </span> <span class="text-muted">{{ details.mark_needed_to_win }}</span>
                </div>
                <div class="fl-te-c mt-1">
                    <div class="">
                        <span>Created By : </span> <span class="text-muted">{{ details.created_by }}</span>
                    </div>
                    <div class="">
                        <span>Created At : </span> <span class="text-muted">{{ details.created_at }}</span>
                    </div>
                </div>

            </div>
        </modal>

    </div>
</template>

<script>
import urls from '@/data/urls';
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import DetailsCard from '../../common-components/DetailsCard';

export default {
    name: 'InterviewTemplateListing',
    components: { DetailsCard },
    computed: {
        ...mapGetters(['currentUser'])
    },
    data () {
        return {
            dataLoading: false,
            data: [], // Loaded Template data
            details: {}, // Selected Template details for modal
            editingItem: null, // Item being edited
            deletingItem: null, // Item being deleted
            listUrl: urls.interview.listTemplate,
            addEditUrl: urls.interview.createTemplate,
            deleteUrl: urls.interview.deleteTemplate
        };
    },
    mounted () {
        this.loadTemplates();
    },
    methods: {
        async loadTemplates () {
            this.dataLoading = true;
            try {
                const response = await axios.get(this.listUrl, {
                    params: {
                        user: this.currentUser.username
                    }
                });
                this.data = response.data.data;
            } catch (error) {
                console.error('Error loading templates:', error);
            } finally {
                this.dataLoading = false;
            }
        },
        showAddModal () {
            this.$refs.addModal.show();
        },
        showDescription (item) {
            console.log('item', item);
            this.details = { ...item };
            this.$refs.detailsModal.show();
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.loadTemplates();
            this.editingItem = null;
        },
        formFailed () {
            this.$notify('Failed to submit the form, please try again later.', 'Failed', { type: 'danger' });
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', { type: 'success' });
            this.loadTemplates();
        }
    }
};
</script>

<style scoped>
.align-vertically-center {
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    min-height: 60vh; /* Set height to full viewport or container height */
}
</style>
