<template>
    <div class="container">
        <h4 class="p-0 text-primary">Attended Interviews</h4>

        <!-- Loading state -->
        <div class="m-5 mt-7 text-center" v-if="dataLoading">
            <loading-animation></loading-animation>
            <br/>
            <div>Please wait while templates are being loaded...</div>
        </div>

        <!-- Details Cards for each template -->
        <div class="row" v-if="!dataLoading && data.length !== 0">
            <div v-for="(interview, index) in data.data" :key="index" class="col-12 col-md-6 col-lg-3 mt-4">
                <details-card :data="interview" :title="interview.name" :index="index"
                              @showDescription="viewDetails">
                    <template #body>
                        <div class="row">
                            <div class="col-4">Interviewee</div>
                            <div class="col-8">:
                                <span class="text-muted">{{ interview.user }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">Questions</div>
                            <div class="col-8">:
                                <span class="text-muted">{{ interview.number_of_questions }} nos.</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">Status</div>
                            <div class="col-8">:
                                <span class="text-muted">{{ interview.status }}</span>
                            </div>
                        </div>
                        <div v-if="interview.result" class="row">
                            <div class="col-4">Result</div>
                            <div class="col-8">:
                                <span class="text-muted">{{ interview.result }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                Marks
                            </div>
                            <div class="col-8">:
                                <span class="text-muted">
                                {{ interview.total_marks ? interview.total_marks : '0' }}
                                </span>/
                                <span class="text-muted">
                                {{ parseInt(interview.number_of_questions) * 5 }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #buttons>
                        <div class="btn-group">
                            <btn color="danger" v-if="isAdmin" size="sm" class="btn-block"
                                 @click="setDelete(interview)" title="Delete"/>
                            <btn size="sm" class="btn-block" @click="viewDetails(interview)" title="Details"/>
                        </div>
                    </template>
                </details-card>
            </div>
        </div>

        <!-- Delete Modal -->
        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the interview <b v-html="deletingItem && deletingItem.name"></b>. Are you sure?
            </p>
            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>

        <div v-if="!dataLoading && data.data.length===0" class="text-center align-vertically-center">
            No any interviews have been attended till now.
        </div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import DetailsCard from '../common-components/DetailsCard';

export default {
    name: 'AttendedInterviewListing',
    props: {
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    components: { DetailsCard },
    computed: {
        ...mapGetters(['currentUser']),
        getTitle () {
            return 'Topic : ' + this.details.name;
        }
    },
    data () {
        return {
            scrollIndex: null,
            hoverTimeout: null,
            dataLoading: false,
            data: [],
            details: {},
            listUrl: urls.interview.list,
            deletingItem: null, // Item being deleted
            deleteUrl: urls.interview.delete
        };
    },
    mounted () {
        this.loadAttendedInterviews();
    },
    methods: {
        viewDetails (item) {
            this.$router.push('/interview/' + item.id + '/details/');
        },
        async loadAttendedInterviews () {
            this.dataLoading = true;
            let params = {};
            const userFilter = { user: this.currentUser.username };
            if (!this.isAdmin) {
                params = userFilter;
            }
            const response = await axios.get(this.listUrl, {
                params: params
            });
            this.data = response.data;
            this.dataLoading = false;
        },
        showScrollbar (index) {
            this.hoverTimeout = setTimeout(() => {
                this.scrollIndex = index;
            }, 2000);
        },
        hideScrollbar () {
            clearTimeout(this.hoverTimeout);
            this.scrollIndex = null;
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', { type: 'success' });
            this.loadAttendedInterviews();
        }
    }
};
</script>

<style scoped>
.align-vertically-center {
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    min-height: 60vh; /* Set height to full viewport or container height */
}
</style>
