<template>
    <div class="container">
        <div class="mt-2 fl-te-c card">
            <h4 class="text-primary font-poppins-semibold">TEST CUSTOM PROMPT</h4>
        </div>
        <div class="mt-1 fl-te-c card">
            <s-form>
                    <div class="row">
                        <div class="col-lg-6">
                            <validated-input label="Title" :rules="{required: true}"
                                             class="field-required" border-radius="1" v-model="model.title "></validated-input>
                        </div>
                        <div class="col-lg-6">
                            <validated-ajax-vue-select class="c-input-select field-required" :rules="{required: true}"
                                                   :url="interviewTypeOptionsURL"
                                                   label="Interview Type" v-model="model.type"></validated-ajax-vue-select>
                        </div>
<!--                         <div class="col-lg-6">-->
<!--                            <validated-ajax-vue-select class="c-input-select field-required" :rules="{required: true}"-->
<!--                                                   :url="interviewTopicsOptionsURL"-->
<!--                                                   label="Interview Topic" v-model="model.topic"></validated-ajax-vue-select>-->
<!--                        </div>-->
                        <div class="col-lg-6">
                            <validated-input class="field-required" :rules="{required: true}"
                                                   label="Interview Topic" v-model="model.topic"></validated-input>
                        </div>
                        <div class="col-lg-6">
                        <validated-input label="No of Questions" :rules="{required: true}"
                                                 class="field-required" border-radius="1" v-model="model.no_of_questions " type="number"></validated-input>
                        </div>
                        <div class="col-lg-12">
                            <validated-text-area label="Custom Prompt" :rules="{required: true}"
                                                 class="field-required" border-radius="1" v-model="model.custom_prompt" size="50"></validated-text-area>
                        </div>
                        <div class="col-12 text-right mt-4">
                            <btn text="Generate" @click="GenerateQuestion" :loading="loading" loading-text="Generating..." class="px-4"></btn>
                        </div>
                    </div>
            </s-form>
            </div>
        <div class="card mt-2">
              <!-- Display Questions if Available -->
            <div v-if="questions.length" class="mt-4 card p-3">
<!--                <h4>Generated Questions</h4>-->
<!--                <ul>-->
<!--                    <li v-for="(question, index) in questions" :key="index">-->
<!--                        <strong>{{ index + 1 }}. {{ question.question }}</strong>-->
<!--                        <ul>-->
<!--                            <li v-for="(choice, choiceIndex) in question.choices" :key="choiceIndex">-->
<!--                                {{ choice }}-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                        <p><strong>Correct Answer:</strong> {{ question.correct_ans }}</p>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
                <div class="question-container">
      <div v-for="(item, index) in questions" :key="index" class="question-card">
          <div class="row">
          <div class="col-lg-9">
        <b>{{ index + 1 }}. {{ item.question }}</b>
        <ul class="options-list">
          <li v-for="(choice, choiceIndex) in item.choices" :key="choiceIndex">
            <label>
              <input type="radio" :name="'question-' + index" :value="choice" />
              {{ choice }}
            </label>
          </li>
        </ul>
          <b>Correct Answer: {{ item.correct_ans }}</b>
              </div>
          </div>
      </div>

    </div>
  </div>
  <div v-else>
    <p>Loading questions...</p>
  </div>
        </div>

</div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'TestCustomPrompt',
    data () {
        return {
            interviewTypeOptionsURL: urls.mcq_interview.interview_type.select,
            interviewTopicsOptionsURL: urls.mcq_interview.interview_sub_topic.select,
            GenerateURL: urls.mcq_interview.interview.testCustomPrompt,
            loading: false,
            questions: [],
            model: {
                title: null,
                type: null,
                topic: null,
                no_of_questions: null,
                custom_prompt: null

            }
        };
    },
    methods: {

        async GenerateQuestion () {
            this.loading = true;
            const response = await axios.form(this.GenerateURL, this.model);
            const result = response.data;
            this.questions = result.data.question_data;
            console.log('Result --------------', result);
            if (result.success) {
                this.$notify(' Questions Generated Successfully', 'Success', { type: 'success' });
                this.loading = false;
            } else {
                const errors = response.data.errors;
                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        let errorMessage = '';
                        if (key === '__all__') {
                            errorMessage = `${errors[key]}`;
                        } else {
                            errorMessage = `${key}:  ${errors[key]}`;
                        }
                        this.$notify(errorMessage, 'Warning', { type: 'warning' });
                    }
                }
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
