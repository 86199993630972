<template>
    <div>
        <div v-if="details && !dataLoading" class="fl-te-c mb-4">
            <h4>Interview Details</h4>
            <btn text="Back" @click="$router.go(-1)" size="sm"/>
        </div>
        <div class="card mb-4">
            <h6>Basic Details</h6>
            <div class="row">
                <div class="col-4 mb-3">
                    <label>Interview ID</label><br/>
                    <span class="text-muted">{{ details.interview.id }}</span>
                </div>
                <div v-if="details.interview.topic" class="col-4 mb-3">
                    <label>Topic</label><br/>
                    <span class="text-muted">{{ details.interview.topic }}</span>
                </div>
                <div v-if="details.interview.number_of_questions" class="col-4 mb-3">
                    <label>Number of Questions</label><br/>
                    <span class="text-muted">{{ details.interview.number_of_questions }}</span>
                </div>
                <div v-if="details.interview.mark_needed_to_win" class="col-4 mb-3">
                    <label>Mark Needed to Win</label><br/>
                    <span class="text-muted">{{ details.interview.mark_needed_to_win }}</span>
                </div>
                <div v-if="details.interview.user" class="col-4 mb-3">
                    <label>Interviewee</label><br/>
                    <span class="text-muted">{{ details.interview.user }}</span>
                </div>
                <div v-if="details.interview.status" class="col-4 mb-3">
                    <label>Status</label><br/>
                    <span class="text-muted">{{ details.interview.status }}</span>
                </div>
                <div v-if="details.interview.result" class="col-4 mb-3">
                    <label>Result</label><br/>
                    <span class="text-muted">{{ details.interview.result }}</span>
                </div>
                <div v-if="details.interview.created_by" class="col-4 mb-3">
                    <label>Created By</label><br/>
                    <span class="text-muted">{{ details.interview.created_by }}</span>
                </div>
                <div v-if="details.interview.created_at" class="col-4 mb-3">
                    <label>Created At</label><br/>
                    <span class="text-muted">{{ details.interview.created_at.split('T')[0] }}</span>
                </div>
                <div v-if="details.interview.total_marks" class="col-4 mb-3">
                    <label>Total Marks</label><br/>
                    <span class="text-muted">{{ details.interview.total_marks }}</span>
                </div>
                <div v-if="details.interview.grade" class="col-4 mb-3">
                    <label>Grade</label><br/>
                    <span class="text-muted">{{ details.interview.grade }}</span>
                </div>
                <div v-if="details.interview.completed_at" class="col-4 mb-3">
                    <label>Completed At</label><br/>
                    <span class="text-muted">{{ details.interview.completed_at }}</span>
                </div>
            </div>
        </div>
        <!--        <div v-if="details.questions.length!==0" class="card mb-4">-->
        <!--            <h6>Interview Details</h6>-->
        <!--            <chat-bubble-->
        <!--                :key="index"-->
        <!--                v-for="( message, index) in messages"-->
        <!--                :role="message.role"-->
        <!--                :caption="message.caption"-->
        <!--                :message="message.message"-->
        <!--            />-->
        <!--        </div>-->
        <p v-if="!details && !dataLoading" class="text-center p-3">No data available.</p>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
// import ChatBubble from '../../../components/ChatBubble';

export default {
    name: 'InterviewDetailsPage',
    // components: { ChatBubble },
    data () {
        return {
            interviewId: this.$route.params.id,
            detailsUrl: urls.interview.details,

            dataLoading: false,
            details: '',
            messages: []
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.form(this.detailsUrl, { id: this.interviewId });
            const json = response.data;

            if (json.error === false) {
                this.details = json.data;

                const questions = this.details.questions;
                for (let i = 0; i < questions.length; i++) {
                    const question = questions[i];
                    this.messages.push({
                        caption: 'Question',
                        role: 'question',
                        message: question.question
                    });

                    const answered = question.answered;
                    if (answered) {
                        this.messages.push({
                            role: 'answer',
                            message: question.answer
                        });

                        this.messages.push({
                            role: 'comment',
                            message: question.comment
                        });
                    }
                }
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped>

</style>
