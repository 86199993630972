<template>
    <div class="home py-5 of-h full-height">
        <h4 class="text-primary text-center mb-5 font-poppins-semibold">MCQ Interview</h4>
        <div class="row px-8">
            <div class="col-lg-3 mt-lg-3" @click="redirect('InterviewType')">
                <dashboard-card-item text="Interview Type"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3" @click="redirect('InterviewTopic')">
                <dashboard-card-item text="Interview Topic"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3" @click="redirect('InterviewSubTopic')">
                <dashboard-card-item text="Interview Sub Topic"></dashboard-card-item>
            </div>
<!--             <div class="col-lg-3 mt-lg-3" @click="redirect('Skill')">-->
<!--                <dashboard-card-item text="Skill"></dashboard-card-item>-->
<!--            </div>-->
            <div class="col-lg-3 mt-lg-3" @click="redirect('Candidate')">
                <dashboard-card-item text="Candidate"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3" @click="redirect('Interview')">
                <dashboard-card-item text="Interview"></dashboard-card-item>
            </div>

            <div class="col-lg-3 mt-lg-3" @click="redirect('TestCustomPrompt')">
                <dashboard-card-item text="Test Custom Prompt"></dashboard-card-item>
            </div>

<!--            <div v-if="currentUser.admin === true" class="col-lg-3 mt-lg-3" @click="redirect('UserManagement')">-->
<!--                <dashboard-card-item text="User Management"></dashboard-card-item>-->
<!--            </div>-->
<!--            <div v-if="currentUser.admin === true" class="col-lg-3 mt-lg-3" @click="redirect('UserActivity')">-->
<!--                <dashboard-card-item text="User Activity"></dashboard-card-item>-->
<!--            </div>-->
<!--            <div v-if="currentUser.admin === true" class="col-lg-3 mt-lg-3" @click="redirect('UserActivityBackup')">-->
<!--                <dashboard-card-item text="Activity Backup"></dashboard-card-item>-->
<!--            </div>-->
        </div>
    </div>

</template>

<script>
import DashboardCardItem from '../../components/ui/DashboardCardItem';
import { mapGetters } from 'vuex';

export default {
    name: 'MasterHome',
    components: { DashboardCardItem },
    computed: {
        ...mapGetters(['currentUser'])
    },
    data () {
        return {
            urls: {
                InterviewType: '/mcq-interview/interview-type/',
                InterviewTopic: '/mcq-interview/interview-topic/',
                Interview: '/mcq-interview/interview/',
                Skill: '/mcq-interview/skill/',
                Candidate: '/mcq-interview/candidate/',
                InterviewSubTopic: '/mcq-interview/interview-sub-topic/',
                TestCustomPrompt: '/mcq-interview/test-custom-prompt/'
            }
        };
    },
    methods: {
        redirect (URLName) {
            if (URLName === 'InterviewType') {
                this.$router.push({ path: this.urls.InterviewType });
            }
            if (URLName === 'InterviewTopic') {
                this.$router.push({ path: this.urls.InterviewTopic });
            }
            if (URLName === 'Interview') {
                this.$router.push({ path: this.urls.Interview });
            }
            if (URLName === 'Skill') {
                this.$router.push({ path: this.urls.Skill });
            }
            if (URLName === 'Candidate') {
                this.$router.push({ path: this.urls.Candidate });
            }
            if (URLName === 'InterviewSubTopic') {
                this.$router.push({ path: this.urls.InterviewSubTopic });
            }
            if (URLName === 'TestCustomPrompt') {
                this.$router.push({ path: this.urls.TestCustomPrompt });
            }
        }
    }
};

</script>
<style scoped>

</style>
